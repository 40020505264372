import React from "react";

function App(){
    return (
        <main id="app">
            <h2>This is the policy page</h2>
            <section className="description">
                <article className="center">
                    <p>Description of website (its purpose, why is it useful and why should you use it ?)</p>
                </article>
            </section>
        </main>
    );
}

export default App;
