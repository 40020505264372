import React from "react";
import { NavLink } from 'react-router-dom';
import fb from '../assets/facebook.png';
import insta from '../assets/instagram.png';
import twitter from '../assets/twitter.png';
import yt from '../assets/youtube.png';


function Footer() {
    // <ol>
    // <NavLink to='/terms'>Terms of service</NavLink>
    // <NavLink to='/privacy'>Privacy policy</NavLink>
    // <NavLink to='/sitemap'>Sitemap</NavLink>
    // </ol>
    return (
        <footer>
            <section>
                <p>Phone: <span>01232345</span></p>
                <p>Email: <span>some@email.com</span></p>
            </section>
            <section className="socials">
                <a href="//facebook.com" target="_blank" class="hvr-grow">
                    <img src={fb} alt=""/>
                </a>
                <a href="" class="hvr-grow">
                    <img src={twitter} alt=""/>
                </a>
                <a href="//instagram.com" target="_blank" class="hvr-grow">
                    <img src={insta} alt=""/>
                </a>
                <a href="" class="hvr-grow">
                    <img src={yt} alt=""/>
                </a>
            </section>
        </footer>
    );
}

export default Footer;
