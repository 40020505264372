import React from "react";
import thumbnail from '../assets/lifestyle_placeholder.png';

function Lifestyle(){
    return (
        <main id="lifestyle">
            <h4 className="pageTitle">Lifestyle</h4>
            <section id="lifeStyleImageContainer">
                <section>
                    <p className="caption">Caption</p>
                    <input type="button" value="Read More"/>
                </section>
            </section>
            <section className="listContainer">
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                    <aside class="right">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                </section>
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                    <aside class="right">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                </section>
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                    <aside class="right">
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                    </aside>
                </section>
            </section>
        </main>
    );
}

export default Lifestyle;
