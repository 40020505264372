import React from "react";
import leve from '../assets/LB.jpg';

function About(){
    return (
        <main id="about">
            <h4 className="pageTitle">About Tamas</h4>
            <section className="topContainer">
                <aside className="left sidePart">
                    <div className="verticalLine"></div>
                    <div className="whiteArea"></div>
                    <div className="verticalLine bottom"></div>
                </aside>
                <section className="aboutImage">

                </section>
                <aside className="right sidePart">
                    <div className="verticalLine"></div>
                    <div className="whiteArea"></div>
                    <div className="verticalLine bottom"></div>
                </aside>
                <section className="pageTitle">
                    <img className="" src={leve} alt="GT"/>
                </section>
            </section>
            <section className="aboutDescription">
                <div className="divider"></div>
                <section>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </section>
                <div className="divider"></div>
            </section>
        </main>
    );
}

export default About;
