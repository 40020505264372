import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
const API_PATH = 'http://localhost:8080/portfolio-gajdost/core/';///potholeapp/core/index.php';

class Contact extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            message: '',
            mailSent: false,
            error: null,
            longitude: '',
            latitude: ''
        }
    }
    /*
    handleAdd= async e =>{
        await this.setState({
            text : e.target.value
        })
    }
    */

    handleFormSubmit = e => {
      e.preventDefault();
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: {
			'Authorization': 'Basic',
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000'
        },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent
          })
        })
        //.catch(error => this.setState({ error: error.message })
        .catch(() => console.log("Can’t access " + API_PATH + " response. Blocked by browser?"));
    };
    /*
    handleSubmit = e =>{
        e.preventDefault();
        console.log(this.state.text);
        let formData = new FormData();
        formData.append("text",this.state.text);
        const url = "http://localhost:3000/core/";
        axios.post(url,formData)
        .then(res=> console.log(res.data))
        .catch(err=> console.log(err));
    }
    */
    render(){
        return(
            <main id="contact">
                <h4 className="pageTitle">Contact</h4>
                <h4 class="blue">get in touch</h4>

                <section className="contactHolder">
                    <section>
                        <p className="left">Email</p>
                        <p className="right">example@email.com</p>
                    </section>
                    <section>
                        <p className="left">Call</p>
                        <p className="right">00 00 000 00 00</p>
                    </section>
                    <h4 className="subtitle">Socials</h4>
                    <section>
                        <p className="left">Facebook</p>
                        <p className="right">Example Page</p>
                    </section>
                    <section>
                        <p className="left">Instagram</p>
                        <p className="right">@example</p>
                    </section>
                    <section>
                        <p className="left">Twitter</p>
                        <p className="right">@example</p>
                    </section>
                    <section>
                        <p className="left">LinkedIn</p>
                        <p className="right">Example Page</p>
                    </section>
                </section>
            </main>
        );
    }
}

/*
function Contact(){
    return (
        <main id="contact">
            <form className action method="post">
                <h2>Have you got any questions?</h2>
                <h4>Feel free to use the form below to get in touch with us!</h4>
                <div>
                    <label htmlFor="firstName">First name:</label>
                    <input name="firstName" type="text" />
                </div>
                <div>
                    <label htmlFor="lastName">Last name:</label>
                    <input name="lastName" type="text" />
                </div>
                <div>
                    <label htmlFor="email">Email address:</label>
                    <input name="email" type="text" />
                </div>
                <div>
                    <label htmlFor="message">Your message:</label>
                    <textarea name="message" defaultValue={""} />
                </div>
                <div>
                    <input type="submit" defaultValue="Send" />
                </div>
            </form>
        </main>
    );
}
*/
export default Contact;
