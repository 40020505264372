import React from "react";
import placeholder from '../assets/event_placeholder.png';
import thumbnail from '../assets/conference_placeholder.png';

function Events(){
    return (
        <main id="events">
            <h4 className="pageTitle">Events</h4>
            <section className="eventClip">
                <img src={placeholder} alt="Radio link"/>
            </section>
            <section className="listContainer">
                <aside class="left">
                    <h4 class="blue">Past</h4>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                </aside>
                <aside class="right">
                    <h4 class="blue">Upcoming</h4>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                    <section>
                        <img src={thumbnail} alt="Video thumbnail"/>
                        <section>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </section>
                    </section>
                </aside>
            </section>
        </main>
    );
}

export default Events;
