import React from "react";
import ReactDOM from "react-dom";

// import { NavLink, Switch, Route } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Index from '../pages/Index';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Tv from '../pages/TV';
import Radio from '../pages/Radio';
import Lifestyle from '../pages/Lifestyle';
import Events from '../pages/Events';
import Conferences from '../pages/Conferences';
import Achievements from '../pages/Achievements';
import Privacy from '../pages/Privacy';
// import Terms from '../pages/Terms';
// import Sitemap from '../pages/Sitemap';
// import NotFound from '../pages/404';
// import Login from '../core/cms/Login';

function RouteControl(){
    return (
        <Switch>
            <Route exact path='/' component={Index}></Route>
            <Route path='/About' component={About}></Route>
            <Route path='/Contact' component={Contact}></Route>
            <Route path='/Tv' component={Tv}></Route>
            <Route path='/Radio' component={Radio}></Route>
            <Route path='/Lifestyle' component={Lifestyle}></Route>
            <Route path='/Events' component={Events}></Route>
            <Route path='/Conferences' component={Conferences}></Route>
            <Route path='/Achievements' component={Achievements}></Route>
            <Route path='/Privacy' component={Privacy}></Route>
        </Switch>
    );
}

export default RouteControl;
