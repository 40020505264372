import React from "react";
// import { Button, Navbar, Nav, NavItem, MenuItem, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

function Header(){
    return (
        <header>
            <section className="top">
                <h1>Gajdos Tamás</h1>
            </section>
            <Navbar expand="md" variant="light" className="justify-content-around">
                <li><NavLink to='/'>Home</NavLink></li><span>|</span>
                <li><NavLink to='/about'>About</NavLink></li><span>|</span>
                <li><NavLink to='/tv'>TV</NavLink></li><span>|</span>
                <li><NavLink to='/radio'>Radio</NavLink></li><span>|</span>
                <li><NavLink to='/lifestyle'>Lifestyle</NavLink></li><span>|</span>
                <li><NavLink to='/conferences'>Conferences</NavLink></li><span>|</span>
                <li><NavLink to='/events'>Events</NavLink></li><span>|</span>
                <li><NavLink to='/achievements'>Achievements</NavLink></li><span>|</span>
                <li><NavLink to='/contact'>Contact me</NavLink></li>
            </Navbar>
        </header>
        /*
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        */
    );
}

export default Header;
