import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.min.css';

import Header from "./components/Header"; //Because your export is default you don't need braces around your import component name
import Router from "./components/Route";
import Footer from "./components/Footer";
// Convert html to react
// https://magic.reactjs.net/htmltojsx.htm

// Multpile pages - best example yet
// https://blog.pusher.com/getting-started-with-react-router-v4/

// Stackoverflow
// https://stackoverflow.com/questions/41956465/how-to-create-multiple-page-app-using-react

function App() {
  return (
    <div className="App">
        <Header />
        <Router />
        <Footer />
    </div>
  );
}

export default App;
