import React from "react";
import placeholder from '../assets/radio_placeholder.png';
import thumbnail from '../assets/radio_thumbnail.png';

function Radio(){
    return (
        <main id="radio">
            <h4 className="pageTitle">Radio</h4>
            <section className="radioClip">
                <img src={placeholder} alt="Radio link"/>
                <p class="caption">
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                </p>
            </section>
            <section className="listContainer">
                <div className="divider"></div>
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video thumbnail"/>
                    </aside>
                    <aside class="right">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </aside>
                </section>
                <div className="divider"></div>
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video thumbnail"/>
                    </aside>
                    <aside class="right">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </aside>
                </section>
                <div className="divider"></div>
                <section className="clipContainer">
                    <aside class="left">
                        <img src={thumbnail} alt="Video link"/>
                    </aside>
                    <aside class="right">
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </aside>
                </section>
                <div className="divider"></div>
            </section>
        </main>
    );
}

export default Radio;
